import React from 'react'
import { Link } from 'gatsby'
import tw, { css } from 'twin.macro'
import { useElapsedTime } from 'use-elapsed-time'

import Layout from '../components/Layout'
import Seo from '../components/Seo'

import notFoundSymbol from '../images/icons/renga-404.svg'
import plusStar from '../images/icons/pixels/star-plus.svg'
import dotStar from '../images/icons/pixels/star-dot.svg'
import { Container, Grid } from '../components/ui'

const stars = [
  {
    type: 'plus',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'dot',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'plus',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'dot',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'plus',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'dot',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'dot',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'plus',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'dot',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'dot',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'plus',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'dot',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'dot',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'dot',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'dot',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
  {
    type: 'dot',
    position: {
      x: Math.floor(Math.random() * 100),
      y: Math.floor(Math.random() * 100),
    },
    offset: Math.floor(Math.random() * 3 + 2),
  },
]

const NotFoundPage = ({ data }) => {
  const { elapsedTime } = useElapsedTime({ isPlaying: true })

  return (
    <Layout navTheme="light">
      <section tw="relative bg-renga-black w-screen h-screen flex flex-col justify-between items-center overflow-clip py-[50px] lg:py-[70px]">
        <div />

        <div>
          <Container>
            <Grid>
              <div tw="col-span-full lg:(col-start-5 col-span-4)">
                <img src={notFoundSymbol} alt="404" tw="w-full" />
              </div>
            </Grid>
          </Container>
        </div>

        <div tw="z-[2]">
          <Container>
            <Grid>
              <div tw="text-renga-offwhite col-span-full text-[16px] text-center px-14 lg:(col-start-5 col-span-4 text-[18px] px-0)">
                <p tw="mb-4 max-w-[300px] mx-auto">
                  We also like to go pretty far out in our exploration, but
                  looks like we haven’t made it here yet.
                </p>
                <Link
                  className="link"
                  to="/"
                  tw="italic border-b border-b-renga-offwhite lg:(hover:(bg-renga-mint text-renga-black border-b-renga-black))"
                >
                  Back to the Rengaverse
                </Link>
              </div>
            </Grid>
          </Container>
        </div>

        {/* Stars */}
        <div tw="absolute w-full h-full top-0 left-0 z-[1]">
          {stars.map((star, index) => (
            <div
              key={`start-${index}`}
              tw="absolute"
              style={{
                left: `${star.position.x}%`,
                top: `${star.position.y}%`,
              }}
            >
              <img
                src={{ plus: plusStar, dot: dotStar }[star.type]}
                alt=""
                css={[
                  star.type === 'dot' && tw`w-[2px]`,
                  star.type === 'plus' && tw`w-4`,
                ]}
                style={{
                  transform: `translateY(${Math.floor(
                    Math.sin((elapsedTime * 3) / star.offset) * 4
                  )}px)`,
                }}
              />
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const Head = () => <Seo title="Page Not Found" />

export default NotFoundPage
